import type { BuilderFunction } from './types';
import {
  getOnFunction,
  getQueryAllFunction,
  getQueryFunction,
  getEmitFunction,
} from './factories';

export default function elementController(
  fragmentName: string,
  builderFunction: BuilderFunction
) {
  if (!fragmentName && typeof fragmentName !== 'string') {
    throw new TypeError('fragment name is required');
  }

  const elementInstancesNodes = Array.from(
    document.querySelectorAll(`[data-element=${fragmentName}]`) || []
  );
  const elementInstances = [...elementInstancesNodes] as HTMLElement[];

  elementInstances.forEach((element) => {
    builderFunction({
      root: element,
      on: getOnFunction(element),
      query: getQueryFunction(element),
      queryAll: getQueryAllFunction(element),
      emit: getEmitFunction(element),
    });
  });
}
