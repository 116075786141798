import { elementController } from '@public-pages/runtime-core';
import { useSmartScript } from '@public-pages/smartscript';

elementController('modal-app-campaign', ({ query }) => {
  const { generateOneLinkQRCode, generateOneLinkURL } = useSmartScript();

  //#region Elements
  const modal = query('#modal-app-campaign');
  const backdrop = query('#modal-app-campaign-backdrop');
  const closeButton = query('#modal-app-campaign-close');
  const appStoresContainer = query('.modal-app-campaign__img-store');
  const qrCodeImg = query(
    '.modal-app-campaign__img-qrcode-img'
  ) as HTMLImageElement;
  //#endregion

  //#region Methods
  const openModal = () => {
    modal?.classList.add('modal-app-campaign__container--on');
    backdrop?.classList.add('modal-app-campaign__backdrop--on');
  };

  const closeModal = () => {
    modal?.classList.remove('modal-app-campaign__container--on');
    backdrop?.classList.remove('modal-app-campaign__backdrop--on');
  };
  //#endregion

  //#region QR Code Generation
  if (qrCodeImg) {
    generateOneLinkQRCode(
      'https://consumidorpositivo.onelink.me/3ote/pgvkj6v3',
      {
        af_sub3: JSON.stringify({
          type: 'qrcode',
          path: location.pathname,
          location: 'modal/app-campaign',
        }),
      }
    ).then((base64) => {
      if (base64) qrCodeImg.setAttribute('src', base64);
    });
  }
  //#endregion

  if (appStoresContainer) {
    const storeLinksElements = appStoresContainer?.getElementsByTagName('a');
    if (storeLinksElements) {
      generateOneLinkURL(
        'https://consumidorpositivo.onelink.me/3ote/pgvkj6v3',
        {
          af_sub3: JSON.stringify({
            type: 'link',
            path: location.pathname,
            location: 'modal/app-campaign',
          }),
        }
      ).then((clickURL) => {
        Array.from(storeLinksElements).forEach((link) => {
          link.setAttribute('href', clickURL);
        });
      });
    }
  }

  console.log(appStoresContainer?.getElementsByTagName('a'));

  //#region Event Listeners
  backdrop?.addEventListener('click', closeModal);
  closeButton?.addEventListener('click', closeModal);
  window.addEventListener('openModalAppCampaign', openModal);
  //#endregion

  //#region Cleanup
  return () => {
    window.removeEventListener('openModalAppCampaign', openModal);
    backdrop?.removeEventListener('click', closeModal);
    closeButton?.removeEventListener('click', closeModal);
  };
  //#endregion
});
