import { elementController } from '@public-pages/runtime-core';
import { useRedline } from '@services/redline';
import setCookie from '@utils/setCookie';
import getCookie from '@utils/getCookie';
import redirectToApp from '@utils/redirectToApp';
import getPageInfo from '@utils/getPageInfo';

elementController('app-root', ({ root }) => {
  onStart();

  async function onStart() {
    const isLogged = getCookie('cached_sign_in');

    if (isLogged) {
      const { redirectIfLogged } = getPageInfo();

      if (redirectIfLogged){
        redirectToApp();
      }
    }
  }

  setCookie('start_source_url', window.location.href);
  const { startRedline } = useRedline();
  startRedline();
});
