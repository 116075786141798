import { elementController } from '@public-pages/runtime-core';
import { useSmartScript } from '@public-pages/smartscript';

elementController('modal-app', ({ query, queryAll }) => {
  const { generateOneLinkURL } = useSmartScript();
  const modal = query('#modal-app');
  const backdrop = query('#modal-app-backdrop');
  const closeButton = queryAll('.close-modal');
  const downloadAppButton =
    query('.modal-app__body')?.getElementsByTagName('a');

  const isMobile = () => window.innerWidth <= 768;

  const openModal = () => {
    if (isMobile()) {
      modal?.classList.add('modal-app__container--on');
      backdrop?.classList.add('modal-app__backdrop--on');
    }
    document.removeEventListener('DOMContentLoaded', openModal);
  };

  const closeModal = () => {
    modal?.classList.remove('modal-app__container--on');
    backdrop?.classList.remove('modal-app__backdrop--on');

    backdrop?.removeEventListener('click', closeModal);
    closeButton.forEach((button) =>
      button.removeEventListener('click', closeModal)
    );
  };

  if (downloadAppButton) {
    generateOneLinkURL('https://consumidorpositivo.onelink.me/3ote/pgvkj6v3', {
      af_sub3: JSON.stringify({
        type: 'link',
        path: location.pathname,
        location: 'button/baixe-o-app',
      }),
    }).then((clickURL) => {
      if (clickURL) downloadAppButton[0].href = clickURL;
    });
  }

  backdrop?.addEventListener('click', closeModal);
  closeButton.forEach((button) => button.addEventListener('click', closeModal));
  document.addEventListener('DOMContentLoaded', openModal);
});
