import { elementController } from '@public-pages/runtime-core';
import setupConfia from './setupConfia';

elementController('observe', ({ query, queryAll }) => {
  const windowHeight = window?.innerHeight || 0
  const observeElementSelector = "[data-element='observe']"
  const mainElement = query(observeElementSelector)
  const minRatio = 0.1
  const maxRatio = 0.4
  const options = {
    root: mainElement,
    rootMargin: "0px",
    threshold: [minRatio, maxRatio],
  };

  const callback = (entries: any) => {
    entries.forEach((entry: any) => {
      const isHigherThanWindow = entry?.boundingClientRect?.height >= windowHeight
      const isIntersectingMin = entry?.intersectionRatio >= minRatio
      const isIntersectingMax = entry?.intersectionRatio >= maxRatio

      if ( (isHigherThanWindow && isIntersectingMin) || isIntersectingMax) {
        entry.target.classList.add('animate')
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);

  const defaultObserversSections = () => {
    const allChildren = queryAll(`${observeElementSelector} > *`)

    allChildren.map(section => {
      observer.observe(section);
    })
  }

  const observeSelfElement = () => {
    const allSelfObserved = queryAll("[data-element='observe-self']")

    allSelfObserved.map(self => {
      observer.observe(self);
    })
  }

  setupConfia()
  defaultObserversSections()
  observeSelfElement()

});
