import { elementController } from '@public-pages/runtime-core';

elementController('modal-redirect-iq', ({ query }) => {
  const modal = query('#modal-redirect-iq');
  const closeButton = query('#modal-redirect-iq-close');
  const redirectButton = query(
    '.modal-redirect-iq__section__container__body__button'
  );

  const openModal = () => {
    if (modal) {
      modal.classList.add('modal-redirect-iq__section--on');
    }
  };

  const closeModal = () => {
    if (modal) {
      modal.classList.remove('modal-redirect-iq__section--on');
    }
  };

  const checkUrlParameter = (param: string): boolean => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(`Checking URL parameter: ${param}`, urlParams.has(param));
    return urlParams.has(param);
  };

  const handleBackdropClick = (event: MouseEvent) => {
    if (event.target === modal) {
      closeModal();
    }
  };

  if (checkUrlParameter('showModal')) {
    openModal();
  }

  closeButton?.addEventListener('click', closeModal);
  redirectButton?.addEventListener('click', closeModal);
});
