import { elementController } from '@public-pages/runtime-core';
import { useSmartScript } from '@public-pages/smartscript';
import { useTracking } from '@services/tracking';

const { generateOneLinkURL } = useSmartScript({
  oneLinkURL: 'https://consumidorpositivo.onelink.me/gaEq',
});

document.addEventListener('DOMContentLoaded', () => {
  const footerElement = document.querySelector(
    '.footer-action-bar'
  ) as HTMLElement;
  const targetElementSelector = footerElement?.dataset.targetSelector;

  elementController('observe', ({ query }) => {
    const targetElement = query(`[data-element="${targetElementSelector}"]`);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            footerElement.classList.add('footer-action-bar--visible');
          } else {
            footerElement.classList.remove('footer-action-bar--visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(targetElement as HTMLElement);
  });
});

elementController('footer-action-bar', ({ query }) => {
  const clickButton = query('#footer-action-button');
  const { elementClickedEvent } = useTracking();

  clickButton?.addEventListener('click', handleGotoStore);

  async function handleGotoStore() {
    const clickURL = await generateOneLinkURL(
      'https://app.consumidorpositivo.com.br'
    );

    elementClickedEvent({
      elementType: 'button/text',
      text: `Banner footer - Redirecionado para Store`,
      location: window.location.pathname,
    });

    window.open(clickURL, '_top');
  }
});
