import { getAnonymousIDAsync } from 'redline-client-sdk';

export default async function setupConfia() {
  const userId = await getAnonymousIDAsync();

  (window as any).relyconfig = {
    workspaceSlug: 'cp',
    userId,
    customId: userId,
    privacyPolicyLink: '/termos-e-politica',
    btnBorderStyle: 'round',
    primaryColor: '#0048DB',
    secondaryColor: '#E2E4E9',
    btnBorderRadius: '32px',
    btnTextColor: '#FFFFFF',
    containerTextColor: '#16181D',
    linkTextColor: '#0048DB',
  };

  const script = document.createElement('script');
  script.defer = true;
  script.src = import.meta.env?.PUBLIC_CONFIA_URL;
  document.body.insertAdjacentElement('beforeend', script);
}
