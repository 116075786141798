import { elementController } from '@public-pages/runtime-core';
import { useSmartScript } from '@public-pages/smartscript';
import { useTracking } from '@services/tracking';

elementController('footer-cp', ({ query, queryAll }) => {
  const { generateOneLinkURL } = useSmartScript();
  const { elementClickedEvent } = useTracking();
  const footerLinks = queryAll('.footer-cp__link') as HTMLAnchorElement[];
  const footerSocialMedia = queryAll(
    '.footer-cp__links-icon'
  ) as HTMLAnchorElement[];

  const appLinks = query('#app-links')?.getElementsByTagName('a');

  if (appLinks?.length) {
    generateOneLinkURL('https://consumidorpositivo.onelink.me/3ote/pgvkj6v3', {
      af_sub3: JSON.stringify({
        type: 'link',
        path: location.pathname,
        location: 'footer/app-links',
      }),
    }).then((clickURL) => {
      if (clickURL) {
        Array.from(appLinks).forEach((link) => {
          link.setAttribute('href', clickURL);
        });
      }
    });
  }

  [...footerLinks, ...footerSocialMedia].map((link) =>
    link.addEventListener('click', handleLinkTrack)
  );

  function handleLinkTrack(this: HTMLAnchorElement) {
    elementClickedEvent({
      elementType: 'link',
      location: 'footer',
      position: this.dataset.category,
      text: this.dataset.type || this.text,
    });
  }
});
